import logo from '../../assets/balenahub_logo_small.png';
import { CustomHelmet, Material } from '@balena/ui-shared-components';

const { Typography, Grid, Container, Card } = Material;

export const Terms = () => {
	const meta = {
		title: 'balenaHub Terms of use | balena',
		description: 'Terms of use for balenaHub.',
		properties: {
			'og:title': 'balenaHub Terms of use | balena',
			'og:description': `Terms of use for balenaHub.`,
			'og:image': logo,
			'og:url': window.location.href,
			'twitter:title': `balenaHub Terms of use | balena`,
			'twitter:description': `Terms of use for balenaHub.`,
			'twitter:image': logo,
		},
	};
	return (
		<>
			<CustomHelmet {...meta} />
			<Container maxWidth="xl">
				<Grid container p={[3, 3, 5]}>
					<Grid item xs={12} sm={12} md={4} lg={3}>
						<Typography variant="h3" mb={3}>
							Terms of Use
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={8} lg={9}>
						<Card>
							<Typography variant="h4">Who we are</Typography>
							<Typography variant="body1">
								We are Balena Inc. (we, our, us or Balena); our address is 300
								Delaware Ave, Suite 210, Wilmington DE 19801.
							</Typography>
							<Typography variant="h3" my={5}>
								About these Terms
							</Typography>
							<Typography variant="h4" mt={4}>
								How these Terms Apply
							</Typography>
							<Typography variant="body1">
								Welcome to BalenaHub ("BalenaHub"). Please read these terms and
								conditions ("BalenaHub Terms") carefully before accessing or
								using BalenaHub. BalenaHub is an online marketplace which allows
								you to build, distribute, access and deploy tools, applications
								(“apps”) and related content (together, the "Tooling"). Although
								made available on BalenaHub, Tooling may be developed and
								maintained by either an affiliate of Balena or by third-parties
								(including other Users of BalenaHub). By accessing or using
								BalenaHub, you agree to the BalenaHub Terms. If you are entering
								into the BalenaHub Terms on behalf of a company or another legal
								entity, you represent and warrant that you have the authority to
								bind such entity to the BalenaHub Terms, and "you" or "your"
								will refer to such entity. If you do not have such authority, or
								if you do not agree with the BalenaHub Terms, you must not
								accept the BalenaHub Terms and you may not access or use
								BalenaHub. The BalenaHub Terms should be read in conjunction
								with our Terms of Service which also apply to your use of
								BalenaHub. If there is any conflict between the BalenaHub Terms
								and the Terms of Service in relation to BalenaHub, the BalenaHub
								Terms shall prevail.
							</Typography>
							<Typography variant="h4" mt={4}>
								Changes to these Terms
							</Typography>
							We may modify the BalenaHub Terms at any time, in our sole
							discretion. If we do so, we’ll let you know either by posting the
							modified terms on the BalenaHub website or through other
							communications. It’s important that you review the BalenaHub Terms
							whenever we modify them because if you continue to use BalenaHub
							after we have posted modified terms, you are indicating to us that
							you agree to be bound by the modified BalenaHub Terms. If you
							don’t agree to be bound by the modified BalenaHub Terms, then you
							may not use BalenaHub anymore. Because BalenaHub is evolving over
							time we may change or discontinue all or any part of BalenaHub, at
							any time and without notice, at our sole discretion. If you
							violate any of the terms in these BalenaHub Terms, we can
							terminate your use of and access to BalenaHub in our sole
							discretion. You agree to use BalenaHub at your own risk.
							<Typography variant="h4" mt={4}>
								Third party Terms
							</Typography>
							<Typography variant="body1">
								BalenaHub relies on third party partners and providers. Where
								applicable, you will comply with the terms of such third parties
								in your access to and use of BalenaHub. For instance, the source
								code of the Tooling (if any) are currently hosted on GitHub. You
								acknowledge that Balena is not responsible for such third
								parties' terms or for your compliance with them.
							</Typography>
							<Typography variant="h4" mt={4}>
								Date of Last Update
							</Typography>
							<Typography variant="body1">
								These terms were last updated on 6th January 2023.
							</Typography>
							<Typography variant="h3" my={5}>
								Using and Registering for balenaHub{' '}
							</Typography>
							<Typography variant="h4" mt={4}>
								BalenaHub users{' '}
							</Typography>
							<Typography variant="body1">
								Anyone using BalenaHub (a "User") is permitted and encouraged to
								access, create, build, distribute and deploy (as appropriate){' '}
								<Typography color="#eb00ff" component="span">
									Tooling on BalenaHub.
								</Typography>{' '}
								You may not use BalenaHub in violation of export control or
								sanctions laws of the United States or any other applicable
								jurisdiction. You may not use BalenaHub if you are or are
								working on behalf of a Specially Designated National (SDN) or a
								person subject to similar blocking or denied party prohibitions
								administered by a U.S. government agency, or if you are a
								national of a country or region that is subject to a general
								trade embargo administered by a U.S. government agency.
							</Typography>
							<Typography variant="h4" mt={4}>
								Opening an Account
							</Typography>
							<Typography variant="body1">
								In order to use BalenaHub, you do not need an account. However,
								an account may be necessary for certain functionality on
								BalenaHub and we will advise you of this if you try to access
								this functionality. If you choose to, you can open an account
								with us via our website at https://www.balena.io/; to do this,
								you will need to provide certain basic information about
								yourself (such as your name and email address), and you will be
								allocated a user name and login ID. Your account may be subject
								to additional terms of service which will be made available to
								you when you open the account. If you already have an account
								with us, you can use your existing account for BalenaHub.
							</Typography>
							<Typography variant="h4" mt={4}>
								Cost of Use
							</Typography>
							<Typography variant="body1">
								Generally, it is free for you to register with us (if you choose
								to), set up your profile and build, distribute and deploy (as
								appropriate){' '}
								<Typography color="#eb00ff" component="span">
									Tooling on BalenaHub.
								</Typography>{' '}
								If you are a Fleet Owner and you wish to commercialize a Fleet,
								we may impose charges. Balena considers a commercial fleet as
								that which is for-profit and/or connected to or associated with
								income or revenue generation in any shape or form, including but
								not limited to: hardware sale, software sale, or ongoing
								subscription charge. Balena reserves the right to determine if a
								fleet published on balenaHub is commercial; see the Fleets
								section below for further information.
							</Typography>
							<Typography variant="body1">
								We may in the future charge fees for certain enhanced features
								of our services, and you will have the opportunity to review and
								accept such fees prior to incurring them.
							</Typography>
							<Typography variant="h4" mt={4}>
								Your Username and Login ID
							</Typography>
							<Typography variant="body1">
								If you choose to register with us and have a login ID (including
								a username and password or other identifier), please do not give
								this information to anyone else or allow anyone else to use it.
								Unless you have notified us that something has happened to cause
								a risk of unauthorised use of your ID, then any activities that
								occur under your ID will be treated as if they were carried out
								by you. Please notify us immediately (see the contacting us
								section below) if you think that anyone else has become aware of
								your ID in circumstances in which there is a risk of misuse or
								if you have any reason to believe that there is any other risk
								of any unauthorised use of your account. We may disable any
								login ID at any time if, in our opinion: You have not complied
								with any of these BalenaHub Terms; or There is any other risk to
								the security or integrity of our platform or the services; or It
								is necessary or appropriate to protect us, or other Users.
							</Typography>
							<Typography variant="body1">
								If we disable your login ID, we will notify you by email that we
								have done so.
							</Typography>
							<Typography variant="h4" mt={4}>
								Your Profile
							</Typography>
							<Typography variant="body1">
								If you choose to register an account with us, we may give you
								the option to complete your profile page on BalenaHub. We may
								give you the opportunity to provide details about any Tooling
								you make available and any Fleets you join. If we make these
								features available to you, you are responsible for all
								information listed on your profile page and any other
								information you provide to us in connection with your user
								account profile or Tooling, and you promise that all such
								information is, and will remain, accurate; this means that you
								must review it regularly and make sure that all of the
								information on it is kept up-to-date.
							</Typography>
							<Typography variant="body1">
								If we have made this feature available to you, you may delete
								your profile and any Tooling you have made available at any time
								by following the steps set out in your profile.
							</Typography>
							<Typography variant="h4" mt={4}>
								Making Tooling Available
							</Typography>
							<Typography variant="body1">
								It is up to you whether or not to list Tooling you have
								developed or created on BalenaHub. Where relevant, we may in our
								discretion refuse to list any.
							</Typography>
							<Typography variant="body1">
								We do not guarantee that any other users will deploy your
								tooling.
							</Typography>
							<Typography variant="body1">
								If you make any Tooling available to other Users (“Your
								Tooling”), you may include a separate license agreement (e.g.,
								an open source license) with your Tooling and the terms and
								conditions of that license agreement will govern other Users
								rights with respect to Your Tooling. If you do not include a
								separate license agreement with Your Tooling, then the following
								default license terms will apply (the “Default License”): You
								hereby grant to all other Users of Balena Hub a perpetual,
								irrevocable, non-exclusive, worldwide, royalty-free license to
								reproduce, prepare derivative works of, publicly display,
								publicly perform, sublicense, and distribute Your Tooling in
								source or object form. You acknowledge and agree that your
								separate license agreement (if any) or the Default License
								constitute a separate agreement between you and any User who
								accesses Your Tooling, and that Balena will have no liability or
								responsibility based upon or arising from any access to or use
								of Your Tooling by other Users. You also grant to Balena a
								perpetual, irrevocable, non-exclusive, worldwide, royalty-free
								license to reproduce, prepare derivative works of, publicly
								display, publicly perform, sublicense, and distribute Your
								Tooling on and through BalenaHub.
							</Typography>
							<Typography variant="h4" mt={4}>
								Deploying Tooling from Other Users
							</Typography>
							<Typography variant="body1">
								If you choose to access and deploy any Tooling made available by
								another User (“User Provided Tooling”), you agree that you are
								receiving access to and the rights to use that User Provided
								Tooling from the User who made it available (and such User may
								or may not be an affiliate of Balena). When you choose to deploy
								any Tooling, you are entering an agreement with the User who
								created that Tooling and we are not a party to that agreement if
								we are not the User. Each User is solely responsible for the
								Tooling it provides, the content of its Tooling, and any claims
								that you or any other party may have relating to the Tooling or
								your use of it. Balena will have no liability or responsibility
								based upon or arising from any access to or use of any User
								Provided Tooling.
							</Typography>
							<Typography variant="body1">
								Unless required by applicable law, all User Provided Tooling is
								provided on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS
								OF ANY KIND, either express or implied, including, without
								limitation, any warranties or conditions of TITLE,
								NON-INFRINGEMENT, MERCHANTABILITY, or FITNESS FOR A PARTICULAR
								PURPOSE. You are solely responsible for determining the
								appropriateness of using or redistributing Your Tooling or any
								User Provided Tooling and you assume any risks associated with
								your exercise of license grants set forth above.
							</Typography>
							<Typography variant="h4" mt={4}>
								Fleets
							</Typography>
							<Typography variant="body1">
								You may choose to join, or manage, a group of Users using the
								same Tooling on their own devices (a "Fleet"). Each Fleet has a
								Fleet Owner, who is responsible for managing the Fleet and is
								permitted to make updates of the Tooling on your device. By
								joining a Fleet, you consent to the Fleet Owner being authorised
								to make adjustments, upgrades, alterations and modifications to
								the Tooling within the Fleet, including to the Tooling you have
								enabled or deployed on your own device. When you choose to join
								a Fleet, you are entering an agreement with the Fleet Owner and
								we are not a party to that agreement if we are not the Fleet
								Owner. Each User is solely responsible for each Fleet it joins,
								and any claims that you or any other party may have relating to
								the Fleet or your use of it.
							</Typography>
							<Typography variant="body1">
								If you are a Fleet Owner, you agree to manage the Fleet as
								specified in the applicable Tooling and any related
								documentation and in accordance with applicable law. You will
								not access any User's device through the Tooling for any other
								purpose other than as specified in the Fleet description and /
								or Tooling documentation. You will not make any misleading
								statements about the purpose of the Fleet or the Tooling. You
								must not use a Fleet to upload, post, host, or transmit
								unsolicited email, SMSs, or "spam" messages, or to transmit any
								worms or viruses or any code of a destructive nature.
							</Typography>
							<Typography variant="body1">
								IF YOU ARE A NON-PAYING USER OF BALENAHUB, AND YOU ARE A FLEET
								OWNER, YOU MUST NOT CHARGE OTHERS (BY ANY MEANS, NOT LIMITED TO
								DIRECT SALE OF HARDWARE OR SOFTWARE) TO JOIN, USE OR LEAVE YOUR
								FLEET. FLEETS MUST BE OPEN TO ALL USERS WHO WISH TO JOIN. IF YOU
								ARE A PAYING USER OF BALENAHUB (IF AND WHEN SUCH FUNCTIONALITY
								BECOMES AVAILABLE), YOU MAY, SUBJECT TO TERMS AGREED WITH
								BALENA, COMMERCIALIZE YOUR FLEET (INCLUDING BY WAY OF CHARGING
								ANY FEE TO OTHER USERS).
							</Typography>
							<Typography variant="h4" mt={4}>
								Content on balenaHub
							</Typography>
							<Typography variant="body1">
								For purposes of the BalenaHub Terms: (i) “Content” means text,
								graphics, images, music, software, audio, video, works of
								authorship of any kind, and information or other materials that
								are posted, generated, provided or otherwise made available
								through BalenaHub by Users. It does not include the Tooling or
								their associated documentation uploaded by other Users. Subject
								to your compliance with the BalenaHub Terms, Balena grants you a
								limited, non-exclusive, non-transferable, non-sublicensable
								license to download, view, copy, display and print the Content
								solely in connection with your permitted use of BalenaHub. We
								claim no intellectual property rights over the Content, unless
								uploaded by Balena or one of our affiliates. Your profile and
								Content uploaded remain yours. However, by setting your pages to
								be viewed publicly, you agree to allow others to view your
								Content. By setting your repositories to be viewed publicly, you
								agree to allow others to view and fork your Content. You agree
								that Users may access, use and deploy any Tooling that you make
								available on BalenaHub.
							</Typography>
							<Typography variant="body1">
								Balena does not pre-screen Content, but Balena and its designee
								have the right (but not the obligation) in our sole discretion
								to refuse or remove any Content that is available via BalenaHub.
								While Balena prohibits certain conduct and Content on BalenaHub,
								you understand and agree that Balena cannot be responsible for
								the Content posted on BalenaHub and you nonetheless may be
								exposed to such materials.
							</Typography>
							<Typography variant="body1">
								You will defend Balena and its affiliates against any claim,
								demand, suit or proceeding made or brought against Balena by a
								third-party alleging that your Content, or your use of BalenaHub
								in violation of the BalenaHub Terms, infringes or
								misappropriates the intellectual property rights of a
								third-party or violates applicable law, and will indemnify
								Balena for any liabilities, judgments and costs, including for
								reasonable attorney’s fees incurred or sustained by Balena in
								connection with any such claim, demand, suit or proceeding.
							</Typography>
							<Typography variant="body1">
								We’re always trying to improve our products and services, and
								your feedback will help us do that. If you choose to give us any
								ideas, know-how, algorithms, code contributions, suggestions,
								enhancement requests, recommendations or any other feedback for
								our products or services (collectively, “Feedback”), you
								acknowledge and agree that Balena will have a royalty-free,
								fully paid-up, worldwide, transferable, sub-licensable,
								irrevocable and perpetual license to implement, use, modify,
								commercially exploit and/or incorporate the Feedback into our
								products, services, and documentation.
							</Typography>
							<Typography variant="h3" my={5}>
								Our Warranties and Liability
							</Typography>
							<Typography variant="body1">
								Balena does not warrant that (i) BalenaHub will meet your
								specific requirements, (ii) BalenaHub will be uninterrupted,
								timely, secure, or error-free, (iii) the results that may be
								obtained from the use of BalenaHub or any Tooling will be
								accurate or reliable, (iv) the quality of any Tooling, products,
								services, information, or other material purchased or obtained
								by you through BalenaHub will meet your expectations, (v) any
								errors in BalenaHub will be corrected or (vi) that Tooling are
								free of viruses or other harmful or malicious code. Any
								warranties, conditions or other terms implied by law, including
								fitness for purpose and satisfactory quality, are expressly
								excluded.
							</Typography>
							<Typography variant="body1">
								You acknowledge that Balena is not responsible for the
								provision, support or content of any Tooling, unless we choose
								to make support available for Tooling which we (or our
								affiliates) have provided, or for the availability, reliability
								or quality of any third party service on which the source code
								of any Tooling are hosted (where applicable). If you have any
								questions about any Tooling, you may be able to contact the User
								which made that Tooling available, through BalenaHub or other
								methods made known by that User. BalenaHub does not guarantee
								that you will be able to contact any User or that the User will
								respond to your query or fix any errors with any Tooling or its
								availability.
							</Typography>
							<Typography variant="body1">
								You expressly understand and agree that Balena will not be
								liable (whether in contract, tort, including negligence,
								misrepresentation or for any other reason) for any (i) loss of
								profits, (ii) anticipated cost savings, (iii) goodwill, (iv) use
								of access to data (v) other intangible losses, or (vi) indirect,
								incidental, special, consequential or exemplary damages (even if
								Balena has been advised of the possibility of such damages),
								resulting from: (i) the use or the inability to use BalenaHub;
								(ii) the cost of procurement of substitute goods and services
								resulting from any goods, data, information or services
								purchased or obtained or messages received or transactions
								entered into through or from BalenaHub; (iii) unauthorized
								access to or alteration of your transmissions or data; (iv)
								statements or conduct of any third-party on BalenaHub; (v) or
								any other matter relating to or in connection with BalenaHub.
							</Typography>
							<Typography variant="body1">
								Where prohibited by applicable law, nothing in these BalenaHub
								Terms excludes our liability to you in the event of (a) death or
								personal injury caused by our negligence; (b) fraud; or (c)
								fraudulent misrepresentation. Other than as set above, our
								liability (whether in contract, tort, including negligence,
								misrepresentation or for any other reason) to you for any claim
								arising under the BalenaHub Terms or from use of BalenaHub shall
								be limited to $100 in aggregate.
							</Typography>
							<Typography variant="h3" my={5}>
								Ending these Terms
							</Typography>
							<Typography variant="h4" mt={4}>
								Termination by you
							</Typography>
							<Typography variant="body1">
								You may terminate the BalenaHub Terms at any time with immediate
								effect by ceasing all use of BalenaHub.
							</Typography>
							<Typography variant="h4" mt={4}>
								Termination by us
							</Typography>
							<Typography variant="body1">
								We may terminate the BalenaHub Terms at any time with i mmediate
								effect by giving you written notice, including by email to the
								address you used for your account registration. We may also
								notify you if we intend to cease providing BalenaHub by
								providing a notice on the BalenaHub website.
							</Typography>
							<Typography variant="h4" mt={4}>
								Consequences of termination
							</Typography>
							<Typography variant="body1">
								Following any termination: You will no longer be able to access
								and use BalenaHub; You will no longer be able to access any
								Tooling stored on BalenaHub, unless you have deployed them in
								your own environment; If you have made any Tooling available on
								BalenaHub, your Tooling may still be used by other Users who
								have deployed them prior to the date of termination; You will no
								longer be a member of any Fleet you joined prior to termination.
							</Typography>
							<Typography variant="h4" mt={4}>
								Personal Data
							</Typography>
							<Typography variant="body1">
								Use by us of personal data relating to you and in relation to
								your use of BalenaHub is governed by our privacy policy. Fleet
								Owners are solely responsible for any processing of personal
								data or personal information by them or on their behalf
								(including relating to other Users in the Fleet) in connection
								with their management of the Fleet and Balena shall, without
								prejudice to the section "Our warranties and liability" above
								have no liability to any Users in connection with any Fleet
								Owner's use, misuse or processing of any such personal data or
								personal information.
							</Typography>
							<Typography variant="h4" mt={4}>
								General Terms
							</Typography>
							<Typography variant="body1">
								The failure of Balena to exercise or enforce any right or
								provision of the BalenaHub Terms will not constitute a waiver of
								such right or provision. Other than as noted above with respect
								to the Terms of Service, the BalenaHub Terms constitute the
								entire agreement between you and Balena and govern your use of
								BalenaHub, superseding any prior agreements between you and
								Balena (including, but not limited to, any prior versions of the
								BalenaHub Terms). You agree that the BalenaHub Terms and your
								use of BalenaHub are governed by the federal laws of the United
								States of America and the laws of the State of Washington,
								without regard to conflict of law provisions.
							</Typography>
							<Typography variant="h3" my={5}>
								Contacting Us
							</Typography>
							<Typography variant="body1">
								You may contact us by any of the following means: Via BalenaHub
								or your account; By email at legal@balena.io; By post to: 300
								Delaware Ave, Suite 210, Wilmington DE 19801.
							</Typography>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};
